import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Container, Card } from "react-bootstrap";

import Subscription from "./Subscription";
import {
    useUpdateContactAddress,
    useUpdateShippingAddress,
} from "./profile.services";
import { useQuery } from "react-query";
import { useAuth } from "../../providers/AuthContext";
import Loader from '../../components/Loader';

const Profile = (props) => {

    const navigate = useNavigate();
    const auth = useAuth();
    const { isLoading, error, data: response } = useQuery(["users", auth]);

    const contactAddress = useUpdateContactAddress();
    const shippingAddress = useUpdateShippingAddress();

    if (error === "login") {
        navigate("/login", { state: { from: props.location } });
        return null;
    }

    return (
        <>
            <Helmet title="Profile"/>
            {isLoading && <Loader />}
            {contactAddress?.isLoading && <Loader />}
            {shippingAddress?.isLoading && <Loader />}
            <Container style={{ paddingTop: 10 + 'rem', paddingBottom: 10 + 'rem' }}>
                <Card className="p-4">
                    <Card.Title>
                        <h1 className="text-center fw-bold">Your Profile</h1>
                    </Card.Title>

                    <Card.Body className="p-3">
                        <Card.Text>
                            <h6 className="fw-bold">Username</h6>
                            {response?.data?.username}
                        </Card.Text>
                    </Card.Body>

                    <Card.Body className="my-3 p-3">
                        <Card.Text>
                            <h6 className="fw-bold">Subscription</h6>
                            {response?.data?.subscriptions.map((subscription, index) => (
                                <Subscription key={index} {...subscription} />
                            ))}
                        </Card.Text>
                    </Card.Body>

                    {/* {response?.data?.contactAddress && (
                        <Card.Body className="my-3 p-3">
                            <AddressDisplay
                                title="Contact Address"
                                address={response.data?.contactAddress}
                                addressMutation={contactAddress}
                            />
                        </Card.Body>
                    )}

                    {response?.data?.shippingAddress && (
                        <Card.Body className="my-3 p-3">
                            <AddressDisplay
                                title="Shipping Address"
                                address={response.data?.shippingAddress}
                                addressMutation={shippingAddress}
                            />
                        </Card.Body>
                    )} */}
                </Card>
            </Container>
        </>
    );
};

export default Profile;
