import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm';

const MarkdownText = ({children})=> {
    return (
        <Markdown remarkPlugins={[remarkGfm]}>
            {children}
        </Markdown>
    );
}

export default MarkdownText;