import React, { createContext, useState, useContext } from "react";
import { jwtDecode } from "jwt-decode";

const authContext = createContext();

const useProvideAuth = () => {
  let token = localStorage.getItem("_u");

  const [user, setUser] = useState(
    token !== "undefined" ? JSON.parse(token) : null
  );

  const signIn = (data, callback) => {
    localStorage.setItem("_u", JSON.stringify(data));
    console.log(data);
    setUser(data);
    if (callback) callback();
  };

  const signOut = (callback) => {
    localStorage.removeItem("_u");
    setUser(null);
    if (callback) callback();
  };

  const IsAuthenticated = () => {

    if (!(user && user?.token)) {
      localStorage.removeItem("_u");
      return false;
    }
  
    const decodedJwt = jwtDecode(user.token);
  
    const isAuthenticated = decodedJwt && decodedJwt.exp * 1000 > Date.now();
    
    if (!isAuthenticated) {
      localStorage.removeItem("_u");
    }
    
    return isAuthenticated;

  }

  return {
    user,
    signIn,
    signOut,
    IsAuthenticated
  };
};

const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const useAuth = () => useContext(authContext);

export { ProvideAuth, useAuth };
