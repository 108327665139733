import React from "react";
import errorImage from "../assets/img/error-image.png";
import { Container, Alert, Row, Col } from "react-bootstrap";

const Error = () => {
    return (
        <Container className="text-center py-5">
            <Row>
                <Col>
                    <img src={errorImage} alt="Error" className="img-fluid" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Alert key="danger" variant="danger">
                        There has been an error, please return to the <Alert.Link href="/">home page</Alert.Link> and try again.
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
};

export default Error;
