import { useMutation, useQueryClient } from "react-query";
import { POST } from "../../components/Constants";
import { useAuth } from "../../providers/AuthContext";

export const useUpdateContactAddress = () => {
  const queryClient = useQueryClient();
  const auth = useAuth();

  return useMutation(
    (data) => POST("users/contact-address", auth, data, "PUT"),
    {
      onSettled: () =>
        queryClient.invalidateQueries(["users", auth])
    }
  );
};

export const useUpdateShippingAddress = () => {
  const queryClient = useQueryClient();
  const auth = useAuth();

  return useMutation(
    (data) => POST("users/shipping-address", auth, data, "PUT"),
    {
      onSettled: () =>
        queryClient.invalidateQueries(["users", auth])
    }
  );
};
