import React from "react";

import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthContext";
import { jwtDecode } from "jwt-decode";

function RequireAuth({ children }) {
  const { user, signOut } = useAuth();
  let location = useLocation();
  let navigate = useNavigate();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (user && user?.token) {
    const decodedJwt = jwtDecode(user.token);

    if (decodedJwt.exp * 1000 > Date.now()) {
      return children;
    }
  }

  signOut(()=> navigate("/"));
}

export default RequireAuth;
