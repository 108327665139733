import React from "react";
import { Modal } from "react-bootstrap";

const PpModal = ({ show, setShow }) => {
    return (
        <Modal
            id="privacyModal"
            size="xl"
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="terms-of-membership-and-membership-agreement"
        >
            <Modal.Header closeButton>
                <Modal.Title id="terms-of-membership-and-membership-agreement">
                    Super Motor Club - Privacy Policy
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h1>Privacy Policy</h1>
                    <p>
                        Super Partnerships Worldwide Ltd. is committed to keeping your information secure and take the privacy of all data extremely seriously. We are an ICO registered company (no. ZB290402) and our entry on the register can be viewed <a href="https://ico.org.uk/ESDWebPages/Entry/ZB290402" target="_blank" rel="noreferrer">here.</a>
                    </p>
                    <p>
                        This Privacy Notice has been designed to explain how and why we collect, use, store and delete data as well as how long it is kept for and if any third parties are involved. Your rights will also be clarified. This Privacy Policy applies to all websites and apps operated by Super Partnerships Worldwide Ltd.
                    </p>
                    <p>
                        By entering into the Membership Agreement with Super Partnerships Worldwide or an affiliate, you will become a Member of one of our programmes. A Super Partnerships Membership grants member’s access to numerous discounts and rewards designed to provide you with many great and memorable experiences while saving money.
                    </p>
                    <p>
                        By using the service, accessing the service or providing your information you acknowledge and consent to us collecting, using, processing and sharing your personal information in accordance with this Privacy Policy and our Cookies Policy. You can choose not to provide certain information, however, any data you do not share with us could prevent you from using the service or limit the features available to you.
                    </p>
                    <p>
                        For the purpose of the Data Protection Legislation, the data controller is Super Partnerships Worldwide Ltd ("we and us") of 124 Finchley Road, London, England, NW3 5JS. The data subject is the individual whose data is being processed.
                    </p>
                    <p>
                        Should you have any queries regarding your data, please contact the Data Protection Officer on <a href="mailto:dpo@superclubs.co.uk">dpo@superclubs.co.uk</a> or via our concierge 0330 124 2187.
                    </p>
                    <p>
                        <strong>This Privacy Notice covers</strong>
                    </p><ol>
                        <li>How we collect your personal data</li>
                        <li>What personal data we collect</li>
                        <li>Our legal basis for processing</li>
                        <li>How we use this personal data</li>
                        <li>How we store your data</li>
                        <li>How long we keep your data</li>
                        <li>Who your data is shared with</li>
                        <li>Data Transfers</li>
                        <li>How we delete your data</li>
                        <li>Your rights as a data subject</li>
                        <li>Complaints</li>
                        <li>Subject Access Request</li>
                        <li>Links to other websites</li>
                        <li>Changes to our Privacy Policy</li>
                        <li>Contact Information</li>
                    </ol>
                    <p></p>
                    <p>
                        <strong>How we collect your personal data</strong>
                        <br />We can collect your personal data through the following communication channels:
                    </p><ul>
                        <li>
                            Booking Form, Contact Form, Online Chat, Surveys
                            <ul>
                                <li>The data collected through the Booking Form Contact Form, Online Chat, Surveys will be provided by the data subject with consent.</li>
                            </ul>
                        </li>
                        <li>
                            Telephone, Email, Social Media, Postal
                            <ul>
                                <li>The data collected through Telephone, Email, Social Media, Postal will be placed into our system through our booking form. The data subject will be informed of processing data as well as sent an email and/or letter to identify the storing and processing of data as well as their rights.</li>
                            </ul>
                        </li>
                        <li>The data collected through cookies is with consent. For more information, please see our <a href="https://superclubs.co.uk/Cookie" target="_blank" rel="noreferrer">Cookies Policy</a></li>
                        <li>
                            Through a third-party company/advertisement campaign
                            <ul>
                                <li>The data we collect through external companies will be stored with those companies or securely in our internal system, in one location as identified in our procedures.</li>
                                <li>You may authorise a partner of Super Partnerships Worldwide Ltd. To transfer information to us to authenticate and register you in order to receive the access to the service.</li>
                                <li>We may contact you with a request for more information or for product or service promotion.</li>
                            </ul>
                        </li>
                    </ul>
                    <p></p>
                    <p>
                        <strong>What personal data we collect</strong>
                        <br />We may collect the following personal data:
                    </p><ul>
                        <li>Title</li>
                        <li>Name</li>
                        <li>Contact Telephone Number</li>
                        <li>Contact Email Address</li>
                        <li>Delivery Address</li>
                        <li>Billing Address</li>
                        <li>Billing details will be requested and stored, in line with PCI DSS, with an external company. (To find out more information on this, please contact us)</li>
                        <li>Redemptions</li>
                        <li>Passport information</li>
                        <li>Date of Birth</li>
                        <li>How you heard about us (for Marketing Analysis Purposes)</li>
                        <li>Feedback</li>
                        <li>Recommendations</li>
                        <li>Social Network Account Details</li>
                        <li>Other information may be collected and stored with the authorisation of the account holder</li>
                    </ul>
                    <p></p>
                    <p>
                        Please note, personal data is defined as any data that can identify an individual.
                    </p>
                    <p>
                        Depending on the benefits that you elect to use, you may be asked for additional information to properly service your Membership.
                    </p>
                    <p>
                        For those benefits that require you to submit a reimbursement or cashback claim, you may be required to complete a form requesting information needed to process the claim or a copy of your expenses/receipt as proof of purchase. Depending on the type of claim and the circumstances surrounding the claim, you may be required to provide additional documentation to support your claim such as receipt of purchase with a partnering company.
                    </p>
                    <p>
                        Following the purchase of any products or service, we may request for feedback. The feedback is not anonymous. We may contact you using the personal information provided.
                    </p>
                    <p>
                        Super Partnerships Worldwide Ltd. may use your information to publish you as a competition winner or use your feedback on our website or social media, or to personalise the Membership Service. Any information we share with a wider audience will protect you by not providing your full identifiable information unless you have agreed to do so.
                    </p>
                    <p>
                        Emails and/or texts may be sent using a third-party service, of which, the company will be prohibited from using your personal information for any other reason.
                    </p>
                    <p>
                        Super Partnerships Worldwide Ltd. may offer a referral service so that we can tell your friends about the discounts and rewards available by being a member.
                    </p>
                    <p>
                        The data collected through our websites and social media with the use of cookies includes google analytics, google remarketing, Facebook pixels, demographic data and browsing patterns. We also automatically receive your IP address (which identifies the computer or device that you use to access the Sites); the time and date of your visit; browser; operating system; internet connection details; to monitor Website traffic as well as a breakdown of your journey through our Sites.
                    </p>
                    <p>For more information on the cookies we use, please see our <a href="https://superclubs.co.uk/Cookie" target="_blank" rel="noreferrer">Cookies Policy</a>.</p>
                    <p>
                        You can refuse to provide any information. However, if you do refuse to provide information that is necessary for your membership then your ability to use/receive our products or services may become limited.
                    </p>
                    <p>
                        We may use services such as Facebook to serve tailored advertisements to you about the company, its products, or services. The delivery of other companies, their products or services may occur. When clicking on banners or through to other companies, we allow them to use their own cookies and may share something information you have given to us, however, this does not affect your rights.
                    </p>
                    <p>
                        We may use analytic or advertising platforms or services that allow us to enable you to receive personalised ads. As a result, your details may be saved on a remarketing list. We have the ability to disable advertising features if you do not want to receive personalised ads.
                    </p>
                    <p>
                        When the data subject is an applicant for a vacant or not vacant role, the data we collect will include the information on your CV/Cover Letter including name, contact information and previous and/or current education and/or work experience. We may ask for additional information within a form of a survey. If CV’s are downloaded, they are all stored in one place and dated the download date. Successful applicants will need to provide NI number, Tax code, Student Finance plan (if applicable), Bank details, full address and emergency contact information etc.
                    </p>
                    <p>
                        <strong>Our legal basis for processing</strong><br />
                        The legal ground we are processing your personal data on is to perform a contract with you and we have your consent to do so. We collect, use and process your information to provide you with the services, tailor the service, develop new services and for marketing purposes.
                    </p>
                    <p>
                        We do not generally rely on consent as a lawful basis for processing your personal data, but you have the right to withdraw consent to marketing at any time by contacting us.
                    </p>
                    <p>
                        All information collected and processed will be relevant to your interest/membership or to supply you with the right information regarding new products, services or documentation etc.
                    </p>
                    <p>
                        All calls are recorded for monitoring and training purposes. All emails and/or texts will be stored. This is stated on our automated voice recording that each person will hear when they call the organisation. When taking details on outbound calls, we will notify you as to whether the call is being recorded when taking additional information. They may be produced to the data controller upon request or with regards to a query.
                    </p>
                    <p>
                        We will only process data for the purpose of providing an individual with the most relevant information, products or services. Should our purpose for processing your data change, we will update this policy.
                    </p>
                    <p>
                        <strong>How we use this personal data</strong> <br />
                        We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
                    </p><ul>
                        <li>Internal record keeping</li>
                        <li>To identify you as a Member</li>
                        <li>To allow delivery of aspects of the product to the member/li&gt;
                        </li><li>To improve our products and services</li>
                        <li>To collect payment</li>
                        <li>For marketing purposes including sharing feedback</li>
                        <li>To communicate with you about your account, further products or services that are available to you</li>
                        <li>
                            To ensure the functionality of the product and/or website
                        </li>
                        <li>
                            To ensure you receive information regarding the most relevant products, services and special offers
                        </li>
                        <li>
                            To ensure members receive all information and access with regards to their membership/purchase and updates on our legal, regulatory or operational obligations when necessary
                        </li>
                        <li>
                            To meet research and product/development needs to improve the user experience, rewards, discounts, and membership
                        </li>
                        <li>
                            To analyse the use of the service and tailor content including the product advertisement services available, advertising and prices
                        </li>
                        <li>
                            To resolve issues and queries
                        </li>
                        <li>
                            Contacting you from time to time for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise our websites according to your interests.
                        </li>
                        <li>
                            To build up marketing profiles, to aid strategic development, to manage our relationship with advertisers and to audit usage of the sites.
                        </li>
                        <li>To personalise the experience including target marketing and membership.</li>
                        <li>
                            Additional information may be collected from you.
                        </li>
                        <li>
                            Prevent, detect, investigate, and respond to fraud, unauthorized access/use of the Services, breaches of terms and policies, or other wrongful behaviour.
                        </li>
                        <li>Comply with any procedures, laws, and regulations which apply to us, including those that set retention periods.</li>
                    </ul>
                    <p></p>
                    <p>To ensure all products and services are available to you, we will share certain information with the third parties. You can request for us not to do so, however, any data you do not share with us could prevent you from using the service or limit the features available to you.</p>
                    <p>The services we offer may have links to third-party websites or apps. If you are directed to a partner or third-party website from our website, it may automatically populate some information from your account. By submitting this data and any further data they request your data will be governed by their privacy statement/policy. This will also apply for any cookies they have on their website. We advise that you should read their privacy policy and cookies policy before submitting any data or agreeing to any cookies. We do not take any responsibility for the practices of the third-party sites you click through to.</p>
                    <p>Our services may offer the ability to interact with social plugins allowing us and/or the social network site to receive data from or about you. Your use of the social plugins it to your own discretion and you use of these plugins would be subject to their sites privacy policy. As with third parties, we have no control over the information that is collected, stored or used by social network plugins and do not take any responsibility for the practices of the sites.</p>
                    <p>We may sell, distribute or lease your personal information to third parties unless we do not have your permission or are required by law to do so. We may use your personal information to send you promotional information about ourselves or third parties which we think you may find interesting.</p>
                    <p>You can revoke your consent at any time by sending your written requests via email or post.</p>
                    <p>
                        <strong>How we store your data</strong>
                        <br />
                        We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect.
                    </p>
                    <p>
                        <u>Physical</u>
                    </p><ul>
                        <li>Outer Building intercom and key only access</li>
                        <li>Clear Desk Policy</li>
                    </ul>
                    <u>Electronic</u>
                    <ul>
                        <li>BitLocker passcodes on all Devices</li>
                        <li>All users have individual password access and limited access to only suitable files</li>
                        <li>Group Policy determines and logs the use of IT equipment</li>
                        <li>Firewall and Antivirus across all Devices</li>
                        <li>Only administrator account can enable updates, software downloads and more</li>
                    </ul>
                    <p></p>
                    <p>All data is stored in our UK Office, our server is backed up twice a day.</p>
                    <p>Details from a form or website are downloaded by a team leader or HQ, they are all stored in an orderly manner and dated with the download date. Otherwise, they will remain on the website/CRM.</p>
                    <p>For all emails, calls and call recordings it is stored in an external supplier, you can click here to view their <a href="https://www.zendesk.co.uk/company/customers-partners/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>.</p>
                    <p>
                        <strong>Email Addresses</strong><br />
                        All emails, both those who have consented to receive emails and those who have not (or those who have unsubscribed) are stored in an external mailing software and our internal system. For more information about the mailing software’s we may use, please contact us on 0330 124 2187.
                    </p>
                    <p>Email addresses are kept on our email marketing database so that we can keep a track of the status of the email as well as send emails to those who have consented to receive emails i.e., by subscribing to our mailing list. If you no longer wish to receive our emails, your email address will be kept in our systems in the form of a blacklist so that we do not contact you in the future.</p>
                    <p>You can revoke consent at any time by clicking the unsubscribe button.</p>
                    <p>
                        <strong>How long we keep your data</strong> <br />
                        We will keep your personal data on our records for an ongoing period for the reasons mentioned above, as long as you (the data subject) give consent for us to do so. If you no longer want us to keep your personal data, it is your right to request for its removal at any time, providing you have a legitimate reason.
                    </p>
                    <p>CV’s are kept ongoing. Successful applicants will provide further details which are disposed of after 5 years excluding information needed for referencing purposes.</p>
                    <p>
                        <strong>Who your data is shared with</strong>
                        <br />
                        Your personal data may be shared with third parties in order for you to redeem the rewards and offers available. It will not be accessible to any unauthorised persons willingly.
                    </p>
                    <p>We may share your information with other companies under common ownership or control with Super Partnerships Worldwide Ltd. These companies use your information as described in this Privacy Policy.</p>
                    <p>In order to complete a purchase, we share information about you as a purchaser with third-party payment platforms. This sharing of information is to fulfil the purchase and can include full name, email address, telephone number, billing address. The further details they hold is the card details that only they hold. The information shared with them is subject to their Privacy Policy.</p>
                    <p>We may allow advertisers to choose the types of users who will see their advertisements or promotional offers based on the data we have about you, this information shared would be non-identifiable data. As part of the service, you may be required to click on a third-party link. If you click onto any advertisements or links, we may share the further data including identifiable data. The information shared with them is subject to their Privacy Policy.</p>
                    <p>We may choose to buy or sell assets and may share or transfer user information (including identifiable data) in connection with the evaluation of and entry into transactions or as part of the service. Your information could be part of the assets transferred or acquired by a third party if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control.</p>
                    <p>
                        <strong>Data Transfers</strong><br />
                        All of our data is the process and stored in the UK only. Should this change, you will be updated immediately. Data may be pulled from your account to populate forms in websites you click onto from our site. This is done securely. You will be subject to the third-parties Privacy Policy when submitting any information outside of our Websites and Applications. We strongly advise you to read all third-parties Privacy Policy and Cookies Policy before continuing.
                    </p>
                    <p>
                        <strong>How we delete your data</strong> <br />
                        In certain circumstances, you have the right to have your data deleted or ‘forgotten’. In order to delete your personal data from our records, you must request either verbally or in writing. Once we have received this, we will confirm that the request is to be put forward to the DPO for Approval. The DPO will consider the deletion and ensure it will not affect any other data subject. If it is, the request will be rejected. Should the data not affect a further data subject, it is confirmed for deletion. All data will be deleted within this request excluding the email address. This will be placed on a blacklist for our records.
                    </p>
                    <p>
                        Data is deleted manually from all databases at the stated time. Our backup servers are updated automatically daily, and this is when the backup servers will t0 have the data deleted.
                    </p>
                    <p>
                        <strong>Your rights as a data subject</strong> <br />
                        The GDPR provides the following rights for individuals.
                    </p>
                    <ol>
                        <li><a href="#right1">The right to be informed</a></li>
                        <li><a href="#right2">The right of access</a></li>
                        <li><a href="#right3">The right to rectification</a></li>
                        <li><a href="#right4">The right to erasure (the right to be forgotten)</a></li>
                        <li><a href="#right5">The right to restrict processing</a></li>
                        <li><a href="#right6">The right to data portability</a></li>
                        <li><a href="#right7">The right to object</a></li>
                        <li><a href="#right8">Rights in relation to automated decision making and profiling</a></li>
                    </ol>
                    <section id="right1">
                        <p>
                            <strong>1. The right to be informed</strong><br />
                            You have the right to be given information on how and why your personal data is being processed, such as this Privacy Notice. You are entitled to request this information both before and after providing consent to us, free of charge.
                        </p>
                    </section>
                    <section id="right2">
                        <p>
                            <strong>2. The right of access</strong><br />
                            You have the right to access your personal data at any time (this is called a Subject Access Request). You are entitled to receive confirmation that your data is being processed, have access to your personal data and information on how and why your data is processed. This right allows you to be aware of and confirm that we are processing your data legitimately.
                        </p>
                    </section>
                    <section id="right3">
                        <p>
                            <strong>3. The right to rectification</strong> <br />
                            You have the right to have your personal data amended if it is inaccurate or incomplete. It is your right to have your request for rectification responded to within a month.
                        </p>
                        <p>If you would like to edit any information you can do this by contacting our concierge team on 0330 124 2187.</p>
                    </section>
                    <section id="right4">
                        <p>
                            <strong>4. The right to erasure (the right to be forgotten)</strong> <br />
                            You have the right to request the deletion or removal of your personal data, but only where there is no persuasive reason for its continued processing. For example, you may request to have your data deleted/removed if your personal data is no longer necessary for the purpose it was originally collected/processed. You can request this by contacting our concierge team on 0330 124 2187. Exclusions apply if it will affect any other data subject or any further legal reason.
                        </p>
                    </section>
                    <section id="right5">
                        <p>
                            <strong>5. The right to restrict processing</strong> <br />
                            You have the right to stop your personal data from being processed any further. This means that we are able to store your personal data, but not process it. We shall keep just enough information about you to make sure we can keep your restriction in place from this point on. You can request this by contacting our concierge team on 0330 124 2187.
                        </p>
                    </section>
                    <section id="right6">
                        <p>
                            <strong>6. The right to data portability</strong> <br />
                            This right only applies to personal data you give to a controller (anyone who determines the reasons and ways of processing personal data), where your personal data is being processed because you have given consent or for the performance of a contract and when processing is carried out automatically. You can request this by contacting our concierge team on 0330 124 2187.
                        </p>
                    </section>
                    <section id="right7">
                        <p>
                            <strong>7. The right to object</strong> <br />
                            You have the right to object to processing based on legitimate interests or the performance of a task in the public interest/exercise of official authority (including profiling), direct marketing (including profiling) and processing for purposes of scientific/historical research and statistics. You have the right to withdraw consent at any time. You can request this by contacting our concierge team on 0330 124 2187.
                        </p>
                    </section>
                    <section id="right8">
                        <p>
                            <strong>8. Rights in relation to automated decision making and profiling</strong> <br />
                            You have the right to request for reconsideration of your applications or request should a decision be made through an automated process.
                        </p>
                    </section>
                    <p>
                        The information above has been sourced from the Information Commissioner’s Office (ICO). For their full guide to rights for individuals under the GDPR, please visit: <a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/" target="_blank" rel="noreferrer">https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/</a>
                    </p>
                    <p>
                        <strong>Marketing communication</strong> <br />
                        We may contact you with regards to special promotions or offers from our products or services or a third party. For electronic promotional offers received via email you can opt-out of the marketing communications you can use the unsubscribe options at the bottom of each email or contact our concierge team on 0330 124 2187.
                    </p>
                    <p>We may also contact you for marketing purposes via text message. Should you wish to opt-out of receiving text alerts you can follow the instructions provided in those messages or reply “STOP”.</p>
                    <p>Push notifications may be sent through our applications. We may also use the location of your device. You can stop these by adjusting your setting sin your mobile device.</p>
                    <p>You can opt-out of certain web beacons and cookies by amending your browser settings, further information of this can be found in our Cookies Policy.</p>
                    <p>
                        <strong>Complaints</strong> <br />
                        If you have a complaint about the way your personal data is being processed or for any other reason relating to your personal data, you have the right to lodge a complaint with a supervisory authority.
                    </p>
                    <p>The supervisory authority is the Information Commissioner's Office, you can contact them on 0303 123 1113 or via their other methods of communication: <a href="https://ico.org.uk/global/contact-us/" target="_blank" rel="noreferrer">https://ico.org.uk/global/contact-us/.</a></p>
                    <p>
                        <strong>Subject Access Requests</strong> <br />
                        As per your right of access, you are entitled to request to see your personal data that is stored in our records. In order to do this, you must send us your request in writing, so that we may be able to tell you whether any of your personal data is being processed, provide you with a description of your personal data, the reasons for which it is being processed and whether it will be shared with any other party. You will also be given a copy of this information and the details of where it is available.
                    </p>
                    <p>The information provided within the Data subject access request is different depending on the data we have on you. However, we will happily provide you with all the information excluding internal notes for chasing individuals on pending matters. This can include but is not limited to:</p>
                    <ul>
                        <li>Title</li>
                        <li>Name</li>
                        <li>Contact Telephone Number</li>
                        <li>Contact Email Address</li>
                        <li>Delivery Address</li>
                        <li>Billing Address</li>
                        <li>Where your Billing details are stored</li>
                        <li>Redemptions</li>
                        <li>Date of Birth</li>
                        <li>Passport information</li>
                        <li>How you heard about us (for Marketing Analysis Purposes)</li>
                        <li>Feedback</li>
                        <li>Recommendations</li>
                        <li>Payment dates and times</li>
                        <li>Social Network Account Details</li>
                        <li>Other information may be collected and stored with the authorisation of the account holder</li>
                    </ul>
                    <p>If you wish to use our Subject Access Request Form to make your request, please contact us at <a href="mailto:dpo@superclubs.co.uk">dpo@superclubs.co.uk</a> or call our office at 0330 124 2187.</p>
                    <p>
                        <strong>Links to other websites</strong><br />
                        Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this Privacy Notice. You should exercise caution and look at the privacy statement applicable to the website in question.
                    </p>
                    <p>
                        <strong>Changes to our Privacy Notice</strong> <br />
                        This Policy may be updated from to reflect changes for legal, regulatory or operational purposes. We encourage you to periodically review this page for the latest information on our privacy practices.
                    </p>
                    <p>If there are any material changes to this Policy, we will update this policy. If we are required by law to do so, we will seek your consent prior to those material changes becoming effective.</p>
                    <p>If you do not accept any changes made to this Policy, please discontinue the use of the Websites, applications and the Service. You will need to contact us to discontinue any memberships or pending product/service you have with us. You may also be required to return anything sent to you in the post.</p>
                    <p>
                        <strong>How to contact us</strong>
                        We are happy to assist you with any request you may have.
                    </p>
                    <div>
                        Super Partnerships Worldwide Ltd. <br />
                        FAO The Data Protection Officer <br />
                        24 Finchley Road <br />
                        London<br />
                        NW3 5JS <br />
                        Email: <a href="mailto:dpo@superclubs.co.uk">dpo@superclubs.co.uk</a>
                    </div>
                    <p>Telephone: 0330 124 2187</p>
                </div>

            </Modal.Body>
        </Modal>
    );
}

export default PpModal;