import React from "react";
import { Card } from "react-bootstrap";
import FormatDatetime from "../../components/FormatDateTime";

const Subscription = ({ pricePlan, expiresOn }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Text>Subscription: {pricePlan}</Card.Text>
        <Card.Text>Expires On: {FormatDatetime(expiresOn, true)}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Subscription;
