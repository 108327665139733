import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

import logo from "../../assets/img/super-travel-club-logo-white.png";
import { useAuth } from "../../providers/AuthContext";
import { POST } from "../../components/Constants";

const Navigation = () => {
    const auth = useAuth();
    const isAuthenticated = auth?.IsAuthenticated();
    let navigate = useNavigate();
    const handleLogout = () => POST("users/logout", auth).then(() => {
        auth.signOut(() => navigate("/"))
    });

    return (
        <Navbar sticky="top" variant="dark" expand="md">
            <Container>
                <Navbar.Brand to={"/"} as={Link} className="py-0">
                    <img
                        src={logo}
                        className="d-inline-block align-top"
                        alt="Super Travel Club Logo"
                        style={{
                            maxWidth: "150px",
                            marginRight: "10px"
                        }}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav>
                        {isAuthenticated && (
                            <>
                            <NavDropdown
                                id="nav-dropdown-dark-example"
                                title={`Hello, ${auth.user.firstName}`}
                                drop="down"
                                menuVariant="dark"
                            >
                                <NavDropdown.Item to={"/profile"} as={Link}>My Profile</NavDropdown.Item>
                                <NavDropdown.Item to={"/change-password"} as={Link}>Change Passcode</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                            </>
                        )}
                        {!isAuthenticated && (
                            <>
                                <Nav.Link to={"/login"} as={Link}>Login</Nav.Link>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;