import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Testimonials from "./Testimonials";
import { useAuth } from "../../providers/AuthContext";
import "./css/home.css";

const HomePage = () => {
    const navigate = useNavigate();
    const { IsAuthenticated } = useAuth();

    const _isAuthenticated = IsAuthenticated();

    return (
        <>
            <Helmet title="Save on all your travel needs throughout the year!" />
            <div className="container-fluid" id="home-leading-img">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="container">
                                <div className="h-100 col-12 col-lg-9 col-xl-6">
                                    <h1 id="introText" className="fw-bolder text-dark"><strong>Save Over £1000 <br /> On All Your Travel Needs!</strong></h1>
                                </div>
                                <Button variant="light" className="btn btn-pink btn-lg mt-4 px-5 text-uppercase btn-larger" onClick={() => navigate("/rewards")}>{_isAuthenticated ? "Rewards" : "Login"}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid text-center pb-5" id="home-benefits">
                <div className="container">

                    <div className="row pb-5">
                        <div className="col-12 col-lg-8 offset-lg-2">
                            <h1 className="color-pink display-5 fw-bold">Just £129.99 For A Full Year Of Unlimited Travel Discounts!</h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="benefit-card">
                                <h2>Up To 75% Off 600,000 Hotels Worldwide</h2>
                                <video playsInline={true} width="100%" autoPlay={true} muted={true} loop={true} preload="auto" controls="1">
                                    <source src="./video/stc-video-01.mp4" type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="benefit-card">
                                <h2>4,000 Dream Resorts From Just £269/Week</h2>
                                <video playsInline={true} width="100%" autoPlay={true} muted={true} loop={true} preload="auto" controls="1">
                                    <source src="./video/stc-video-02.mp4" type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-6 col-lg-4 d-flex">
                            <div className="benefit-card">
                                <h4>Free Priority Pass Membership</h4>
                                <img src="./img/lounge-access.jpg" className="img-fluid" alt="Priority Pass Membership" />
                            </div>
                        </div>

                        <div className="col-6 col-lg-4 d-flex">
                            <div className="benefit-card">
                                <h4>Free Worldwide Golf Membership</h4>
                                <img src="./img/golf.jpeg" className="img-fluid" alt="Golf Membership" />
                            </div>
                        </div>

                        <div className="col-6 col-lg-4 d-flex">
                            <div className="benefit-card">
                                <h4>£25 Gift Card With Every Booking</h4>
                                <img src="./img/easyjet.jpg" className="img-fluid" alt="Easyjet" />
                            </div>
                        </div>

                        <div className="col-6 col-lg-4 d-flex">
                            <div className="benefit-card">
                                <h4>£25 Voucher With Every Booking</h4>
                                <img src="./img/jet2holidays.jpg" className="img-fluid" alt="Jet2Holidays" />
                            </div>
                        </div>

                        <div className="col-6 col-lg-4 d-flex">
                            <div className="benefit-card">
                                <h4>Airport Shopping, Dining and Spa Discounts</h4>
                                <img src="./img/airport-shopping.jpg" className="img-fluid" alt="Airport Shopping" />
                            </div>
                        </div>

                        <div className="col-6 col-lg-4 d-flex">
                            <div className="benefit-card">
                                <h4>Discounted Spa Breaks At 350+ UK Spas & Hotels</h4>
                                <img src="./img/spa.jpg" className="img-fluid" alt="Spa Breaks" />
                            </div>
                        </div>

                        <div className="col-6 col-lg-4 d-flex">
                            <div className="benefit-card">
                                <h4>Airport Parking - 15% Discount</h4>
                                <img src="./img/airport-parking.jpg" className="img-fluid" alt="Airport Parking" />
                            </div>
                        </div>

                        <div className="col-6 col-lg-4 d-flex">
                            <div className="benefit-card">
                                <h4>15% Discount For National Express</h4>
                                <img src="./img/national_express.jpg" className="img-fluid" alt="National Express" />
                            </div>
                        </div>

                        <div className="col-6 offset-3 offset-lg-0 col-lg-4 d-flex">
                            <div className="benefit-card">
                                <h4>Up to 10% Off Year-Round Hoseasons Breaks</h4>
                                <img src="./img/hoseasons.jpg" className="img-fluid" alt="Hoseasons" />
                            </div>
                        </div>

                    </div>

                    <div className="row py-5">
                        <div className="col-12 col-lg-8 offset-lg-2">
                            <h1 className="color-pink display-5 fw-bold">And Much More Inside!</h1>
                            <Button variant="light" className="btn btn-pink btn-lg mt-4 px-5 text-uppercase btn-larger" onClick={() => navigate("/rewards")}>{_isAuthenticated ? "Rewards" : "Login"}</Button>
                        </div>
                    </div>


                </div>
            </div>

            <div className="container-fluid" id="testimonial-section">
                <div className="container">
                    <div className="row text-center mb-5">
                        <div className="col-12">
                            <h1 className="display-5 color-white">Here's What Members Love About Super Travel Club</h1>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-12">
                            <Testimonials />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid color-white" id="contact-hero">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <img src="./img/concierge.jpg" className="img-fluid" alt="The Super Travel Club Concierge Service" />
                        </div>
                        <div id="contact-info-panel" className="col-12 col-md-6 py-5">
                            <h2 className="display-3 fw-light">The Super Travel Club Concierge Service</h2>
                            <h3 className="fw-light mt-4">Your Personal Hotline To Get The Most From Your Membership!</h3>
                            <h4 className="fw-bold mt-4">Just £129.99 for the year!</h4>
                            <a className="btn btn-pink btn-lg mt-5 px-5 text-uppercase btn-larger" href="tel:03301241318">Call Now: 0330 124 1318</a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default HomePage;