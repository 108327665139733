const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
];

const FormatDatetime = (value, justDate = false) => {
    const d = new Date(value);
    const dayName = days[d.getDay()];
    const date = d.getDate();
    const monthName = months[d.getMonth()];
    const year = d.getFullYear();
    const hours = d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

    if (justDate) {
        return `${dayName}, ${date} ${monthName} ${year}`;
    }

    return `${dayName}, ${date} ${monthName} ${year} @ ${hours}`;

};

export default FormatDatetime;