import React from "react";
import { Carousel } from "react-bootstrap";

const Testimonials = () => {
    return (
        <Carousel>
            {/* <Carousel.Item>
                <div className="review-card">
                    <img className="testimonial-headshot rounded-circle img-fluid" src="./img/testimonial-tom.jpg" alt="Tom" />
                    <h3 className="fw-light mb-4">"My mates and I use easyJet a lot and I can get the discount on every booking, which means more weekends away!"</h3>
                    <h3 className="fw-bold mb-4">Tom I.</h3>
                </div>
            </Carousel.Item> */}
            <Carousel.Item>
                <div className="review-card">
                    <img className="testimonial-headshot rounded-circle img-fluid" src="./img/testimonial-sylvia.jpg" alt="Sylvia" />
                    <h3 className="fw-light mb-4">"I'd never visited an airport lounge before I joined super Travel Cub, and now I’m hooked – no more food courts for me!"</h3>
                    <h3 className="fw-bold mb-4">Sylvia J.</h3>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="review-card">
                    <img className="testimonial-headshot rounded-circle img-fluid" src="./img/testimonial-lucy.jpg" alt="Lucy" />
                    <h3 className="fw-light mb-4">"One hotel booking and the membership paid for itself for the year. Being a Super Travel Club member is a no-brainer."</h3>
                    <h3 className="fw-bold mb-4">Lucy V.</h3>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="review-card">
                    <img className="testimonial-headshot rounded-circle img-fluid" src="./img/testimonial-cameron.jpg" alt="Cameron" />
                    <h3 className="fw-light mb-4">"As a mad keen golfer I can play at courses throughout Europe for a lot less than the usual green fees. Excellent value and very worthwhile."</h3>
                    <h3 className="fw-bold mb-4">Cameron S.</h3>
                </div>
            </Carousel.Item>
        </Carousel>
    );
}

export default Testimonials;