const BASEURL = `${process.env.REACT_APP_API_ENDPOINT}/api/v1`;

const _BuildOptions = (method, token, data) => {
  let request = {
    method: method,
    headers: {
      "Content-Type": "application/json"
    }
  };

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  if (data) {
    request.body = JSON.stringify({
      ...data,
      productFamilyId: process.env.REACT_APP_FAMILY
    });
  }

  return request;
};

const _RefreshToken = async ({ user, signIn, signOut }) => {
  const response = await fetch(
    `${BASEURL}/authenticate/refresh`,
    _BuildOptions("POST", undefined, {
      token: user.token
    })
  );

  if (response.ok) {
    const result = await response.json();
    if (result.isSuccess) {
      signIn(result.data, () => {});
      return response;
    }
  }

  signOut();
  return Promise.reject("login");
};

const DefaultQueryFn = async ({ queryKey }) => {
  const [_key, auth] = queryKey;

  const response = await fetch(
    `${BASEURL}/${_key}`,
    _BuildOptions("GET", auth.user.token)
  );

  if (response.status === 401) {
    return await _RefreshToken(auth);
  }

  if (!response.ok) {
    throw new Error("Network response was not Ok");
  }

  return await response.json();
};

const POST = async (url, auth, data, method = "POST") => {
  const response = await fetch(
    `${BASEURL}/${url}`,
    _BuildOptions(method, auth?.user?.token, data)
  );

  if (response.status === 204) {
    return response;
  }

  if (!url.endsWith("authenticate") && response.status === 401) {
    auth.signOut();
    return Promise.reject("login");
  }

  if (response.ok) {
    return (await response.json()) || response.statusText;
  }

  return Promise.reject((await response.json()) || response.statusText);
};

export { DefaultQueryFn, POST, BASEURL };
