import React from "react";

import { Modal } from "react-bootstrap";

const TncModal = ({ show, setShow }) => {

    return (
        <Modal
            id="termsModal"
            size="xl"
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="terms-of-membership-and-membership-agreement"
        >
            <Modal.Header closeButton>
                <Modal.Title id="terms-of-membership-and-membership-agreement">
                    TERMS OF MEMBERSHIP AND MEMBERSHIP AGREEMENT.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>The following Membership Agreement is between Super Partnerships Worldwide Ltd ("we and us") and the person whose name appears on the account and/or welcome email/letter ("you").</p>
                    <p>YOU ARE URGED TO READ THE TERMS OF MEMBERSHIP AND MEMBERSHIP AGREEMENT CAREFULLY AND TO COMMUNICATE ANY QUESTIONS THAT MAY ARISE REGARDING YOUR MEMBERSHIP TO OUR SUPERCONCIERGE TEAM ON 0330 124 2187, 9.00AM to 5.30PM MONDAY to FRIDAY.</p>
                    <p>Memberships are available to UK Residents aged 18 or over and who are legally able of entering a binding contract. Memberships are void where prohibited by law.</p>
                    <ol style={{ listStyleType: "decimal-leading-zero;" }}>
                        <li><strong>Membership Offer:</strong> As an Exclusive Member, you are entitled to Benefits and/or other Discounts on certain products and services offered by participating vendors ("Benefits") shown on the membership website, Welcome letter, and Welcome Email.</li>
                        <li><strong>Exclusive Member Offers:</strong> Members will be eligible for ongoing and additional benefits as part of their membership. You may, on occasion, be introduced to Exclusive offers such as this one where a charge would apply. Prior to any charges we will send you an introduction letter such as this one. If we have an email on file, you may have also received an email. You will have 14 days to trial the membership if this membership is for you and unless you notify us you do not want to receive this offer; we will conveniently bill your original billing source so you can become a full annual member.<br />You may cancel your membership at any time within this Trial Period. When you cancel, you will no longer have access to any of the Benefits from this time. You are not entitled to a refund on any agreed introductory, trial or postage charges if applicable.</li>
                        <li><strong>Membership Term:</strong> Your Membership is effective for one (1) year commencing on the date starting from the day we successfully billed you for this membership.</li>
                        <li><strong>Renewal of Membership:</strong> Your membership will automatically renew on the annual anniversary unless you notify us that you wish to terminate this agreement and cancel your Membership by following the cancellation instructions as outlined in Section 10. A cancellation request must be received before the request to bill has occurred to avoid any additional charges.</li>
                        <li><strong>Collecting Payments:</strong> If there is an issue with collecting using original billing source, we may try again several times for a period of up to, or beyond six (6) months from due date. The date we retrieve these funds will become your new renewal date.<br />If we are unable to bill your original billing source, you agree to provide an alternative billing source and we will contact you to arrange this. We have the right to share your details securely with a third party to retrieve your billing information or reattempt your billing details. If you are not up to date on your payments, we have the right to withdraw or restrict your membership until your account is up to date. If you fail to pay your Membership fee, either through the authorised billing source, or any alternative means within thirty (30) days of the fee being due, then we shall have the right to terminate your Membership. Termination means you no longer have the right to redeem any rewards. </li>
                        <li><strong>Active Member:</strong> You are deemed an "Active Member" immediately upon successful registration until a payment is unpaid or a request to cancel has been actioned. If you have requested to cancel at the end of your subscription, you will remain an "Active Member" until your renewal date.</li>
                        <li><strong>Full Member:</strong> A "Full Member" is defined as a Member who has passed the trial/ preview period, has not contacted us through any of the required methods to cancel their Membership and who is up to date with payment for their Membership. </li>
                        <li><strong>Use of Membership:</strong> You will promptly notify us if you become aware of any unauthorised use of your Membership Benefits.</li>
                        <li><strong>Unlocking Rewards:</strong> As a Full Member you may be entitled to "unlock" additional rewards. Some rewards require you to book in advance. All rewards have their own terms of use and may only be available for a limited time, further details about the rewards can be found on the website and app. Should you wish to not receive any Exclusive Member offers please contact our customer service team on 0330 124 2187.</li>
                        <li><strong>Disclaimer of Liability:</strong> You agree that we are not responsible or liable for providing benefits as these are solely provided by participating vendors and if you have any claims relating to any of these Benefits, you will claim directly against the vendor providing the Benefit. We make or give no warranties or representations, express or imply whether as to satisfactory quality or fitness for a particular purpose or otherwise, with respect to any of the goods purchased from or services provided by any of the participating vendors or related information provided by them to you. Under no circumstances shall our liability exceed a months’ worth of the Membership fee and under no circumstances shall we be liable for your incidental or consequential damages. We reserve the right to eliminate, add, change and substitute Benefits and participating vendors without notice to you. We assume no responsibility for the payment of or contribution to VAT or similar tax, which taxing authorities may impose and such taxes, to the extent imposed, shall remain your sole responsibility or that of the vendor of the Benefits, as the case may be. Postal charges can be applied to trial details to cover the costs of shipping of either no or partially tracked letters which can be first or second class, we make or give no warranties or representations, express or imply that you will receive this item and it is your responsibility to contact us before your trial is due to end if you have not received it. THIS DISCLAIMER DOES NOT AFFECT YOUR STATUTORY RIGHTS.</li>
                        <li><strong>Cancellation Policy</strong> You will not incur any fees to cancel your account. If you have an Introductory, Trial or Preview Period you can cancel your Membership at any time within the Trial Period. Where possible, please allow at least seventy-two (72) hours before the payment renewal date to avoid being charged.<br />Cancellations outside of the Trial Period must be made at least seventy-two (72) hours before the payment renewal date. <br />You must request to cancel your exclusive membership by calling 0330 124 2187, so we can verify your account and cancellations will only be processed this way.<br />Once cancelled you are no longer an Active member and will not have access to any benefits and rewards redeemed. If you have booked a reward in advance, you will be denied that reward at the date of redemption.<br />When you cancel your exclusive membership, you are agreeing that you will no longer attempt to use our service and will return any cards, paperwork or anything else deemed intellectual property of Super Partnerships Worldwide Ltd. You are not entitled to a refund on any postage and packaging charges or the fee for the month or year in which the membership was cancelled or any prior payments within your agreement.</li>
                        <li><strong>Refund Policy:</strong> All fees are non-refundable. We will allow a customer to cancel during their trial or renewal charge date with no additional costs incurred.</li>
                        <li><strong>Entire Agreement:</strong> This Agreement contains all the Terms of Membership and warranties, representations, terms, conditions, inducements, promises or agreements concerning the Exclusive Membership and unless expressly included in this Agreement they shall not form part of this Agreement or have any force or effect. If any of the terms of this Agreement shall become invalid or unenforceable, the remaining terms shall not be affected.</li>
                        <li><strong>Governing Law:</strong> This Agreement and the Terms of Membership shall be governed and construed in accordance with English law.</li>
                        <li><strong>Data Protection:</strong> Your personal details will be held by us and used by us for Membership administration. We may also contact you regarding offers and rewards from partners for you to get the most value out of your membership. We will disclose your details to any people involved with the processing of the Membership services. We shall always require the recipients of your information to keep it confidential and not to disclose to any unauthorised parties. Further details can be found within our Privacy Policy. By becoming an Exclusive Member, you expressly consent to this.</li>
                        <li><strong>Reservation of rights:</strong> We reserve the right to eliminate, add, change, and substitute benefits and vendors without notice. We further reserve the right to change the terms and conditions of this agreement and any Membership policies at any time without prior notice.</li>
                        <li><strong>Cashback Claims:</strong> If you are entitled to claim cashback as part of a Membership and you want to claim via email, please email a copy of the receipt as proof of purchase of the company you signed up through to <a href="mailto:cashback@superclubs.co.uk">cashback@superclubs.co.uk</a>. If you want to claim via post, please post a copy of the receipt as proof of purchase of the company you signed up through to Super Clubs P.O. Box 7450, London, W1U 6TJ. We will send a cheque to you within 4 weeks of receiving the copy of your purchase. Cheques can only be made payable in the name of the membership holder. You need to be an active member at the time of sending your cheque. Only one cashback can be claimed per member and a unique receipt must be provided for the cashback claimed. For any queries, call our Concierge Team on 0330 124 2187.</li>
                        <li><strong>Complaints Procedure:</strong> Our complaints procedure can be found on our website or you can request it from our Concierge Team on 0330 124 2187.</li>
                    </ol>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default TncModal;