const LinkOpenNewTab = ({ url }) => {
    const openNewTab = (url) => {
      if (url) {
        window.open(url, "_blank");
      }
    };
  
    return <div>{openNewTab(url)}</div>;
  };

export default LinkOpenNewTab;