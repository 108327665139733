import { useState } from "react";
import { Outlet } from "react-router-dom";

import Navigation from "../screens/HomePage/Navigation";
import footerLogo from "../assets/img/super-travel-club-logo-white.png";
import TncModal from "../screens/HomePage/TncModal";
import PpModal from "../screens/HomePage/PpModal";

const Layout = () => {
    const [tncShow, setTncShow] = useState(false);
    const [ppShow, setPpShow] = useState(false);

    return (
        <>
            <Navigation />
            <Outlet />
            <footer className="bg-dark color-white py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-2">
                            <img src={footerLogo} className="footer-logo img-fluid" alt="Super Travel Club Logo" />
                        </div>
                        <div className="col-12 col-md-4 col-lg-7">
                            <ul className="footer-link-ul">
                                <li className="footer-link">
                                    <a href="#termsModal" variant="link" onClick={() => setTncShow(true)}>Terms &amp; Conditions</a>
                                </li>
                                <li className="footer-link">
                                    <a href="#privacyModal" variant="link" onClick={() => setPpShow(true)}>Privacy Policy</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 fw-light">
                            <p className="mb-0">Tel: <a href="tel:03301241318">0330 124 1318</a></p>
                            <p className="mb-3">Email: <a href="mailto:hello@supertravelclub.co.uk">hello@supertravelclub.co.uk</a></p>
                            <small>© Super Travel Club</small>
                        </div>
                    </div>
                </div>
            </footer>
            {tncShow && <TncModal show={tncShow} setShow={setTncShow} />}
            {ppShow && <PpModal show={ppShow} setShow={setPpShow} />}
        </>
    );
    
}

export default Layout;