import React, { useState } from 'react';
import { useMutation } from "react-query";
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col, Form, Alert, Button, Card } from "react-bootstrap";

import { POST } from '../../components/Constants';
import Loader from '../../components/Loader';
import { useAuth } from '../../providers/AuthContext';

const ForgotPassword = () => {
    const auth = useAuth();
    const [email, setEmail] = useState("");

    const submit = useMutation(
        (data) => POST("users/forgot-password", undefined, data),
        {
            onSuccess: () => {
                setEmail("");
            },
        }
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        submit.mutate({
            username: email
        });
    }

    if (!!auth?.IsAuthenticated()) return <Navigate to="/rewards" />;

    return (
        <>
            <Helmet title="Forgot Password" />
            <Container style={{ paddingTop: 10 + 'rem', paddingBottom: 10 + 'rem' }}>
                <Row className="justify-content-md-center">
                    <Col md="8" lg="6">
                        <Card className="p-4">
                            <h5 className="text-center mb-4 fw-bold">Forgot your password? No problem, just enter your Customer Number:</h5>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Your Customer Number:</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Your Customer Number" autoFocus required onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>

                                {submit.error && (
                                    <Alert variant="danger small text-center">
                                        {submit.error?.modelState?.custom[0] ?? "Invalid Customer Number"}
                                    </Alert>
                                )}

                                <Button className="btn-pink w-100 mb-4" variant="primary" type="submit" onClick={handleSubmit} disabled={!email || submit.isLoading}>
                                    Submit
                                </Button>

                                {submit.isLoading && <Loader />}

                                {submit.isSuccess && (
                                    <Alert variant="success small text-center">
                                        Passcode Reset email has been sent. Please check your inbox. Need help? Please contact us via email or on the number below.
                                    </Alert>
                                )}

                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ForgotPassword;