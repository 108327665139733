import React from "react";
import { Spinner, Container, Row, Col } from "react-bootstrap";

function Loader() {
    return(
    <Container>
        <Row>
            <Col className="text-center py-3">
                <Spinner animation="grow" size="sm" />
            </Col>
        </Row>
    </Container>);
}

export default Loader;