import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import { Col, Container, Card, Row } from "react-bootstrap";
import { Navigate, generatePath, useNavigate } from "react-router-dom";

import { useAuth } from "../../providers/AuthContext";
import Loader from "../../components/Loader";
import Error from "../../components/Error";

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const Rewards = (props) => {

  const auth = useAuth();
  const navigate = useNavigate();
  const { isLoading, error, data: rewards } = useQuery(["rewards", auth]);

  const handleCardClick = (key) => {
    console.log(key);
    const reward = encodeURI(key);
    const url = generatePath("/rewards/:reward", { reward });
    navigate(url);
  };

  if (error === "login") {
    return <Navigate to={{ pathname: '/login', state: { from: props.location } }} replace />;
  }

  if (isLoading) return <Loader />;
  if (error) return <Error />;

  const groupedData = groupBy(rewards?.data, "categoryKey");
  const keys = Object.keys(groupedData);

  return (
    <>
      <Helmet title="Rewards"/>
      <Container fluid id="portalHeader" className="d-flex align-items-center">
        <Container>
          <h1 className="fw-bold display-4">Your Exclusive Benefits</h1>
          <h2>Save over £1,000 on all your travel needs!</h2>
        </Container>
      </Container>

      <Container fluid="md" className="pb-4">

        {/* Only 1 Category? Show All Rewards */}
        {keys.length === 1 && (
          <>
            <Row xs={1} lg={2} className="g-4 mb-4">
              {rewards?.data.slice(0, 2).map((reward, idx) => (
                <Col key={`rwd_${idx}`} className="d-flex">
                  <Card id={reward.key} style={{ cursor: "pointer" }} onClick={() => handleCardClick(reward.key)}>
                    <div className="offer-card-featured-header rounded-top">SPOTLIGHT REWARD</div>
                    <Card.Img src={reward.images.thumbnailTabletDesktop} className="rounded-0" />
                    <Card.Body>
                      <Card.Title className="text-center fw-semibold text-uppercase small mb-0">{reward.titleShort}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row xs={1} lg={2} xl={3} className="g-4">
              {rewards?.data.slice(2).map((reward, idx) => (
                <Col key={`rwd_${idx}`} className="d-flex">
                  <Card id={reward.key} style={{ cursor: "pointer" }} onClick={() => handleCardClick(reward.key)}>
                    <div style={{ position: "relative" }}>
                      {reward.images.retailerBrand && (
                        <img src={reward.images.retailerBrand} style={{ position: "absolute", width: "20%", right: "5%", top: "5%" }} />
                      )}
                      <Card.Img src={reward.images.thumbnailTabletDesktop} className="rounded-0" />
                    </div>
                    <Card.Body>
                      <Card.Title className="text-center fw-semibold text-uppercase small mb-0">{reward.titleShort}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        )}

        {/* More Than 1 Category? Show Rewards by Category. TODO: STILL NEEDS STYLING FOR MULTI-CATEGORY */}
        {keys.length > 1 && keys.map((k, i) => (
          <div key={`cat_${i}`}>
            <Row>
              <Col sm>
                <h4>
                  {groupedData[k][0]?.categoryTitle}
                </h4>
              </Col>
            </Row>
            <Row>
              {groupedData[k]?.map((rwd, i) => (
                <Col key={`rwd_${i}`} sm={4}>
                  <Card id={rwd.key} style={{ cursor: "pointer" }} onClick={() => handleCardClick(rwd.key)}>
                    <Card.Img variant="top" src={rwd.images.coverTabletDesktop} />
                    <Card.Body>
                      <Card.Title>{rwd.titleShort}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        ))}

      </Container>
    </>

  );
}

export default Rewards;