import React, { useState } from 'react';
import { useMutation } from "react-query";
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Alert, Button } from "react-bootstrap";
import { POST } from '../../components/Constants';
import Loader from '../../components/Loader';

const ResetPassword = () => {
    const location = useLocation();
    const [password, setPassword] = useState("");

    const params = new URLSearchParams(location.search);
    const cacheId = params.get("t");

    const submit = useMutation(
        (data) => POST("users/reset-password", undefined, data),
        {
            onSuccess: (data, variables, context) => {
                setPassword("");
            },
        }
    );

    return (
        <>
            <Helmet title='Reset password' />
            <Container>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <h5 className="text-center mb-4">Reset Password</h5>

                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Enter your new password:</Form.Label>
                                <Form.Control type="password" required autoFocus onChange={(e) => setPassword(e.target.value)} />
                            </Form.Group>

                            {submit.error && (
                                <Alert variant="danger" m={2}>
                                    {submit.error?.modelState?.custom[0] ?? "Oops! It looks like the reset link is invalid or has expired. Please try again or request a new reset link."}
                                </Alert>
                            )}

                            <Button
                                variant="primary"
                                type="submit"
                                onClick={() => submit.mutate({
                                    cacheId: cacheId,
                                    newPassword: password
                                })}
                                disabled={submit.isLoading}>
                                Submit
                            </Button>

                            {submit.isLoading && (<Loader />)}

                            {submit.isSuccess && (
                                <Alert variant="success">
                                    <div> Password reset completed.</div>
                                    <Link to="/login">Try logging in.</Link>
                                </Alert>
                            )}

                        </Form>

                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ResetPassword;