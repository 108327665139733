import React, { useState } from "react";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card, Button, Accordion } from "react-bootstrap";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import LinkOpenNewTab from "../../components/LinkOpenNewTab";
import { useAuth } from "../../providers/AuthContext";
import Loader from "../../components/Loader";
import Error from "../../components/Error";
import MarkdownText from "../../components/MarkdownText";

const Redeem = ({ rewardKey }) => {
    const auth = useAuth();
    const { data, isLoading } = useQuery([`rewards/${rewardKey}/redeem`, auth], {
        refetchOnWindowFocus: false,
        retry: false
    });

    if (isLoading) return <Loader />;

    switch (data.data.type) {
        case 1:
        case 5:
            return <LinkOpenNewTab url={data.data.url} />;
        case 2:
        case 3:
            return <ShowCodes code={data?.data?.code} />;
        case "CallNumber":
            return <CallNumber phoneNumber={data?.data?.phoneNumber} />;

        default:
            return (
                <div>
                    Please call our customer service team on the number listed below.
                </div>
            );
    }
};

const CallNumber = ({ phoneNumber }) => {
    return (
        <div>{phoneNumber}</div>
    );
};

const ShowCodes = ({ code }) => {
    return (
        <div className="alert alert-secondary small fw-bold text-center">CODE: {code}</div>
    );
};

const RedeemButton = ({ reward }) => {
    const [isRedeemed, setIsRedeemed] = useState(false);

    const handleRedeem = () => {
        setIsRedeemed(true);
    };

    return (
        <div className="d-grid gap-2">
            <Button
                className={isRedeemed ? "btn-pink disabled btn-lg" : "btn-pink btn-lg"}
                onClick={handleRedeem}
            >
                {isRedeemed ? "Reward Redeemed" : reward?.redemptionMechanism?.label}
            </Button>
            {isRedeemed && <Redeem rewardKey={reward.key} />}
        </div>
    );
};

const RewardDetails = (props) => {
    const navigate = useNavigate();
    const { reward } = useParams();
    const auth = useAuth();

    const {
        isLoading,
        error,
        data: rewardObj
    } = useQuery([`rewards/${reward}`, auth], {
        retry: false
    });

    if (error === "login") {
        return (
            <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
        );
    }

    if (isLoading) return <Loader />
    if (error) return <Error />

    return (
        <>
            <Helmet title={rewardObj?.data?.titleLong} />
            <Container className="pt-3 pb-5">
                <Row>
                    <Col className="pb-3">
                        <Button variant="light" className="btn-sm mb-3" onClick={() => navigate("/rewards")}> &laquo; Back to All Rewards</Button>
                        <h1 className="fw-semibold">{rewardObj?.data?.titleLong}</h1>

                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mb-4">
                        <Card>
                            <div style={{ position: "relative" }}>
                                {rewardObj?.data?.images?.retailerBrand && (
                                    <img src={rewardObj?.data?.images?.retailerBrand} style={{ position: "absolute", width: "15%", right: "5%", top: "5%" }} />
                                )}
                                <Card.Img className="w-100" src={rewardObj?.data?.images?.coverTabletDesktop} />
                            </div>
                        </Card>
                    </Col>
                    <Col xs={12} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title className="fw-bold mb-3">{rewardObj?.data?.descriptionHeader}</Card.Title>
                                <Card.Text>
                                    <MarkdownText>
                                        {rewardObj?.data?.descriptionContent}
                                    </MarkdownText>
                                </Card.Text>
                                <RedeemButton reward={rewardObj?.data} />
                            </Card.Body>

                        </Card>
                    </Col>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><span className="fw-bold">{rewardObj?.data?.faqHeader}</span></Accordion.Header>
                            <Accordion.Body>
                                <MarkdownText>
                                    {rewardObj?.data?.faqContent}
                                </MarkdownText>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><span className="fw-bold">{rewardObj?.data?.termsHeader}</span></Accordion.Header>
                            <Accordion.Body>
                                <MarkdownText>
                                    {rewardObj?.data?.termsContent}
                                </MarkdownText>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
            </Container>
        </>
    );
}

export default RewardDetails;